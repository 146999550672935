import React from "react";
import "./css/Footer.css";

export default function Footer() {
    return (
      <>
          <footer className="container1">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        Copyright © 2000 - 2021 Samajotthan Sewa Sansthan
                    </div>
                </div>
            </div>
        </footer>
      </>
    );
}