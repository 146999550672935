import React from "react";
import "./css/frontend.css";
import "./css/frontend.min.css";

export default function WhatWeDo() {
    return (
        <section class="elementor-element elementor-element-e038bbd elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="e038bbd" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div class="elementor-container elementor-column-gap-default">
				<div class="elementor-row">
				<div class="elementor-element elementor-element-4473049 elementor-column elementor-col-100 elementor-top-column" data-id="4473049" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-e3bf077 elementor-widget elementor-widget-heading" data-id="e3bf077" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">What We Do</h2>		</div>
				</div>
				<div class="elementor-element elementor-element-4dc5936 elementor-widget elementor-widget-divider" data-id="4dc5936" data-element_type="widget" data-widget_type="divider.default">
				<div class="elementor-widget-container">
					<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
		</div>
				</div>
				</div>
				<section class="elementor-element elementor-element-5e29a35 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="5e29a35" data-element_type="section">
						<div class="elementor-container elementor-column-gap-extended">
				<div class="elementor-row">
				<div class="elementor-element elementor-element-97b62ee elementor-column elementor-col-33 elementor-inner-column" data-id="97b62ee" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-75db0f7 elementor-widget elementor-widget-heading" data-id="75db0f7" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">01.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-1e477ff elementor-widget elementor-widget-heading" data-id="1e477ff" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Care for Nature</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-ab6fb18 elementor-widget elementor-widget-text-editor" data-id="ab6fb18" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p>Every year on 5th June, that is the Environment day, we plant about 2000 saplings.</p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div class="elementor-element elementor-element-eb8348b elementor-column elementor-col-33 elementor-inner-column" data-id="eb8348b" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-2dde84d elementor-widget elementor-widget-heading" data-id="2dde84d" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">02.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-90c4aea elementor-widget elementor-widget-heading" data-id="90c4aea" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Care for Animals</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-d1a4cad elementor-widget elementor-widget-text-editor" data-id="d1a4cad" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p>We provide compassionate and loving care to Cows – nurture them, nurse their calves, and look after any medical treatment necessary. We are registered under AWBI.</p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div class="elementor-element elementor-element-6033de4 elementor-column elementor-col-33 elementor-inner-column" data-id="6033de4" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-2557c12 elementor-widget elementor-widget-heading" data-id="2557c12" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">03.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-5bc5263 elementor-widget elementor-widget-heading" data-id="5bc5263" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Care for Health</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-154eb8d elementor-widget elementor-widget-text-editor" data-id="154eb8d" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p>We spread awareness about AIDS, the adverse effects of Tobacco, and Diabetes. We also organise HealthCamps regularly for underprivileged.</p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				<section class="elementor-element elementor-element-e09fbf5 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section" data-id="e09fbf5" data-element_type="section">
						<div class="elementor-container elementor-column-gap-wide">
				<div class="elementor-row">
				<div class="elementor-element elementor-element-f9fb7a1 elementor-column elementor-col-33 elementor-inner-column" data-id="f9fb7a1" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-8a95952 elementor-widget elementor-widget-heading" data-id="8a95952" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">04.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-ed50d53 elementor-widget elementor-widget-heading" data-id="ed50d53" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Educate</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-2796468 elementor-widget elementor-widget-text-editor" data-id="2796468" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p>We provide quality education to underprivileged and also look after the educational expenses for children of our martyred soldiers.</p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div class="elementor-element elementor-element-6b14fd4 elementor-column elementor-col-33 elementor-inner-column" data-id="6b14fd4" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-2e571ac elementor-widget elementor-widget-heading" data-id="2e571ac" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">05.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-944d61a elementor-widget elementor-widget-heading" data-id="944d61a" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Provide Food</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-98843a4 elementor-widget elementor-widget-text-editor" data-id="98843a4" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p>As we all face this pandemic with all we got, trying to help one another and take care of our loved ones. But during these horrific times, many poor souls who have lost their jobs were forced to sleep hungry. To help them, we started Community Kitchen and have provided food for nearly 600 peoples twice a day since then.</p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
				<div class="elementor-element elementor-element-8defcb0 elementor-column elementor-col-33 elementor-inner-column" data-id="8defcb0" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-25e1bc6 elementor-widget elementor-widget-heading" data-id="25e1bc6" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h5 class="elementor-heading-title elementor-size-default">06.</h5>		</div>
				</div>
				<div class="elementor-element elementor-element-f5081eb elementor-widget elementor-widget-heading" data-id="f5081eb" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h3 class="elementor-heading-title elementor-size-default">— We Uplift the Society</h3>		</div>
				</div>
				<div class="elementor-element elementor-element-160e578 elementor-widget elementor-widget-text-editor" data-id="160e578" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><strong><span data-preserver-spaces="true">GIVE A MAN a fish and you feed him for a day. Teach him how to fish and you feed him for his lifetime.&nbsp;</span></strong><span data-preserver-spaces="true">To uplift society, we provide jobs to skilled and unskilled workers, in order to help them cope up with their financial needs, and help them become financially independent.</span></p></div>
				</div>
				</div>
						</div>
			</div>
		</div>
						</div>
			</div>
		</section>
				</div>
						</div>
			</div>
		</div>
						</div>
		</section>
    );

}