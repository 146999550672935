import React from 'react';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import WhatWeDo from '../partials/WhatWeDo';

function AboutWork() {
  return (
    <div className="Landing">
      <Header />
      <WhatWeDo />
      <Footer />
    </div>
  );
}

export default AboutWork;