import React from 'react';
// import {Navbar, Nav, NavDropdown, Form,  FormControl, Button, Container } from 'react-bootstrap'
import './css/AboutUs.css'
import Yogendra from '../Assets/Yogendra-Kumar-Sachan.jpg';

function AboutUs() {
  return (
    <div className="description" id="descr">
      <div
        className="row1"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className="side" data-aos="fade-up" data-aos-duration="3000">
          <div className="welcome3">WHO WE ARE</div>
          <p className="desc3">
            Trying to build a world where Nature is loved, Animals are cared for, Society is aware of serious health issues, Children are well educated, No one sleeps hungry and One has a job to provide for his family.
          </p>
          <p className="desc4">
            Yogendra Kumar Sachan started Samajotthan Sewa Sansthan in 2001, as a way to give something back to the society. He had a vision and a dream for a prosperous society. Back then, he was a College Professor working part-time. He did not have much at his disposal for the vision, but had a generous heart and started to walk on the path which led him to the present day. He always believed that God provides him generously only to pass it on to society. 
          </p>
        </div>
        <div
          className="main"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <img className="l-img" src={ Yogendra } alt="Yogendra Kumar Sachan" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;