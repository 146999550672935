import React from "react";
import "./css/HeroHome.css";

function HeroHome(props) {
  return (
    <div className="v-landing" id="hero">
        <div className="bgGradient"></div>
        <div class="tag">
            <div className="brandName">Samajyotthan Sewa Sansthan</div>
            <div className="tagLine">
                Jio Aur Jeene Do
            </div>
        </div>
    </div>
  );
}

export default HeroHome;