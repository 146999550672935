import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import Footer from '../partials/Footer';
import AboutUs from '../partials/AboutUs';
// import FeaturesHome from '../partials/Features';

function Landing() {
  return (
    <div className="Landing">
      <Header />
      <HeroHome />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default Landing;